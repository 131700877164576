.sidebar-outer-container {
    width: 100%;
    height: 100vh;
    flex-shrink: 0;
    position: fixed;
    top: 0;
    left: -120%;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition: left .5s ease-out;
}

.sidebar-container {
    color: white;
    background-color: #3b4248;
    min-width: 224px;
    max-width: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-right: 2px solid #4b5563;
}

.open-menu-btn, .close-menu-btn {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
}

.bg-drop {
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.close-menu-btn {
    align-self: flex-end;
    margin: .5rem .5rem 0 0;
}

.new-document-btn {
    font-size: .95rem;
    line-height: 1.25rem;
    letter-spacing: 1px;
    padding: .5rem .75rem;
    margin-top: 1.5rem;
    cursor: pointer;
    color: var(--compare-btn-color);
    background-color: var(--compare-btn-bg-color);
    border: none;
    border-radius: 5px;
    align-self: flex-end;
}

.new-document-btn:hover {
    background-color: var(--compare-btn-hover-color);
}

.new-document-btn:active {
    background-color: var(--compare-btn-active-color);
}

.documents-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0 .5rem;
    gap: 1rem;
}

.documents-list {
    width: 100%;
    border-top: 2px solid #4b5563;
    padding-top: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style-type: none;
}

.document-item {
    width: 100%;
    padding: .5rem;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    transition: background-color .15s ease-out;
}

.document-item:hover {
    background-color: #737373;
}

.document-item.selected {
    background-color: #737373;
    color: white;
}

@media only screen and (min-width: 920px) {
    .sidebar-outer-container {
        left: 0 !important; /* override inline style set by js */
        width: unset;
    }

    .bg-drop {
        display: none;
    }
    
    .open-menu-btn, .close-menu-btn {
        display: none;
    }

    .new-document-btn {
        margin-top: 2.5rem;
    }
}