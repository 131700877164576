.content-body {
    margin: 1rem .5rem 0 1.5rem;
}

.content-body ol {
    list-style-position: outside;
}

.content-body ol li {
    margin-bottom: .5rem;
}

.content-body ol li::marker {
    font-size: 1.05em;
}

.content-body ol li .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.25rem;
}