.actions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 1.5rem;
    padding-bottom: 0.75rem;
}

.actions {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 1rem;
}

.actions-container button {
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: 1px;
    border: none;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: .75rem .95rem;
}

.actions-container span {
    color: #F0FDF4;
    margin: 0 2rem 0;
}

.compare-btn {
    color: var(--compare-btn-color);
    background-color: var(--compare-btn-bg-color);
}

.compare-btn:hover {
    background-color: var(--compare-btn-hover-color);
}

.compare-btn:active {
    background-color: var(--compare-btn-active-color);
}

.add-choice-btn,
.edit-choice-btn {
    color: var(--add-choice-btn-color);
    background-color: var(--add-choice-btn-bg-color);
}

.add-choice-btn:hover,
.edit-choice-btn:hover {
    background-color: var(--add-choice-btn-hover-color);
}

.add-choice-btn:active,
.edit-choice-btn:active {
    background-color: var(--add-choice-btn-active-color);
}