.editing-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
}

.editing-title-input {
    padding: 3px;
    border: 1px solid transparent;
    font-size: .95rem;
    width: 85%;
}

.editing-title-input:focus {
    outline: none;
    box-shadow: 0 0 5px #0EA5E9;
    border-color: #0EA5E9;
}

.editing-title-controls {
    display: flex;
    justify-content: center;
    gap: 6px;
    padding-left: 6px;
}

.editing-title-controls button {
    display: flex;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

.delete-choice-btn {
    color: var(--delete-btn-color);
    background-color: var(--delete-btn-bg-color);
    margin-right: 1rem;
    padding: 3px 5px !important;
}

.delete-choice-btn:hover {
    background-color: var(--delete-btn-hover-color);
}

.delete-choice-btn:active {
    background-color: var(--delete-btn-active-color);
}

.confirm-edit-title {
    color: var(--confirm-btn-color);
    background-color: var(--confirm-btn-bg-color);
    border-color: #fff;
}

.confirm-edit-title:hover {
    background-color: var(--confirm-btn-hover-color);
}

.confirm-edit-title:active {
    background-color: var(--confirm-btn-active-color);
}

.cancel-edit-title {
    color: var(--cancel-btn-color);
    background-color: var(--cancel-btn-bg-color);
}

.cancel-edit-title:hover {
    background-color: var(--cancel-btn-hover-color);
}

.cancel-edit-title:active {
    background-color: var(--cancel-btn-active-color);
}