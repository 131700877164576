.content {
    min-height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: opacity 1s ease-out;
}

.content:first-child {
    color: var(--pros-color);
    background-color: var(--pros-bg-color);
    border-bottom-left-radius: 5px;
}

.content:last-child {
    color: var(--cons-color);
    background-color: var(--cons-bg-color);
    border-bottom-right-radius: 5px;
}

@media only screen and (min-width: 576px) {
    .content {
        width: 50%;
    }
}