.document-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.document-name {
    cursor: pointer;
    padding: 3px;
}

.save-notice {
    color: lightgray;
}