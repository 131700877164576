.new-item-input {
    font-size: 1rem;
    width: 100%;
    vertical-align: top;
    resize: vertical;
    padding: 3px 0 3px 3px;
    border: 1px solid transparent;
}

.content:first-child .new-item-input {
    border-color: var(--black);
}

.content:last-child .new-item-input {
    border-color: var(--black);
}

.content .new-item-input:focus {
    outline: none;
    box-shadow: 0 0 5px #0EA5E9;
    border-color: #0EA5E9;
}