.weight {
    color: currentColor; 
    font-weight: 600;
    padding: 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid currentColor;
    border-radius: 5px;
    cursor: default;
    transition: color .2s ease-in-out,
                background-color .25s ease-in-out;
}

.weight.enabled:hover {
    background-color: #fff;
    color: var(--black);
}

.content:first-child .weight.enabled:hover {
    border-color: transparent;
}

.weight-value {
    user-select: none;
    font-size: 1.125rem;
    line-height: 1;
}

.weight.enabled:hover button {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
}

.weight button {
    opacity: 0;
    visibility: hidden;
    display: flex;
    padding: 0;
    margin: -0.95rem 0 0;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: currentColor;
    transition: opacity 0s ease-out .1s, margin .1s ease-out;
}

.weight.showControls {
    background-color: #fff;
    color: var(--black);
    flex-direction: row;
    padding: 3px 0;
}

.weight.showControls .weight-inc {
    transform: rotate(90deg);
    order: 3;
}

.weight.showControls .weight-dec {
    transform: rotate(90deg);
    order: 1;
}

.weight.showControls .weight-value {
    order: 2;
    padding: 0 2px;
}

.weight.showControls button {
    opacity: 1;
    margin-top: 0;
    visibility: visible;
}

.content:first-child .weight.showControls {
    border-color: transparent;
}