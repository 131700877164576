.controls > button {
    border-radius: 5px;
    padding: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    visibility: hidden;
}

/* .content-body ol li:hover .controls {
    padding-right: 0;
}

.content-body ol li:hover .controls > button {
    visibility: visible;
}

.edit-btn {
    background-color: transparent;
    margin-right: 4px;
}

.edit-btn:hover {
    background-color: var(--edit-item-hover);
    border-color: transparent;
}

.edit-btn:active {
    background-color: var(--edit-item-active);
}

.content:first-child .edit-btn {
    color: var(--white);
    border: 1px solid var(--white);
}

.content:last-child .edit-btn {
    color: var(--second-column-color);
    border: 1px solid var(--second-column-color);
}

.content:last-child .edit-btn:hover {
    color: var(--white);
    border-color: transparent;
}

.delete-btn {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
}

.delete-btn:hover {
    background-color: var(--delete-item-hover);
}

.delete-btn:active {
    background-color: var(--delete-item-active);
}

.content:first-child .delete-btn {
    color: var(--white);
    border-color: var(--white);
}

.content:last-child .delete-btn {
    color: var(--second-column-color);
    border-color: var(--second-column-color);
}

.content:last-child .delete-btn:hover {
    color: var(--white);
    border-color: transparent;
}
*/