@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@400;500;600;700&display=swap');

.card,
.card input,
.card textarea,
.card button {
    font-family: 'Signika Negative', sans-serif;
    line-height: 1.5;
    letter-spacing: .5px;
}

input, textarea {
    color: var(--black);
}

.card-container {
    width: 100%;
    max-width: 640px;
    font-size: 1.125rem;
    line-height: 1.75;
}

.card {
    transition: opacity 1s ease-out;
}

.opacity-75 {
    opacity: .75;
}