.main {
    width: 100%;
    height: 100%;
    padding: 1rem 0 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: .75rem;
}

.main-group-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}

.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.choice-list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    margin-bottom: 2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

@media only screen and (min-width: 420px) {
    .choice-list {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}

@media only screen and (min-width: 920px) {
    .main {
        padding-left: 280px; /* sidebar max width */
    }
}