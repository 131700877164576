:root {
  --black: #1E293B;

  --choice-title-color: #fff;
  --choice-title-bg-color: #8e412e;

  --pros-color: #fff;
  --pros-bg-color: #BA6F4D;

  --cons-color: #BA6F4D;
  --cons-bg-color: #fffbeb;

  --confirm-btn-color: #fff;
  --confirm-btn-bg-color: #22c55e;
  --confirm-btn-hover-color: #16a34a;
  --confirm-btn-active-color: #15803d;

  --cancel-btn-color: #1E293B;
  --cancel-btn-bg-color: #d4d4d8;
  --cancel-btn-hover-color: #a1a1aa;
  --cancel-btn-active-color: #71717a;

  --delete-btn-color: #fff;
  --delete-btn-bg-color: #ef4444;
  --delete-btn-hover-color: #dc2626;
  --delete-btn-active-color: #b91c1c;

  --compare-btn-color: #fff;
  --compare-btn-bg-color: #6a994e;
  --compare-btn-hover-color: #4d7c0f;
  --compare-btn-active-color: #3f6212;

  --add-choice-btn-color: #fff;
  --add-choice-btn-bg-color: #ed7117;
  --add-choice-btn-hover-color: #dd571c;
  --add-choice-btn-active-color: #c2410c;
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scrollbar-width: thin;
}

html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track {
  width: .5rem;
  background-color: #1f2937;
}

html::-webkit-scrollbar-thumb {
  background-color: #6b7280;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #212529;
  min-height: 100vh;
  padding: 0 .5rem 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}