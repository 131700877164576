.new-item-btn-controls {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    padding: 4px 0 4px;
}

.new-item-btn-controls button {
    display: flex;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

.delete-item {
    margin-right: 1rem;
    color: var(--delete-btn-color);
    background-color: var(--delete-btn-bg-color);
    padding: 3px 5px !important;
}

.delete-item:hover {
    background-color: var(--delete-btn-hover-color);
}

.delete-item:active {
    background-color: var(--delete-btn-active-color);
}

.content:first-child .new-item-btn-controls button {
    border-color: var(--pros-color);
}

.content:last-child .new-item-btn-controls button {
    border-color: var(--cons-color);
}

.confirm-new-item {
    background-color: var(--confirm-btn-bg-color);
    color: var(--confirm-btn-color);
    border-color: var(--confirm-btn-color);
}

.confirm-new-item:hover {
    background-color: var(--confirm-btn-hover-color);
}

.confirm-new-item:active {
    background-color: var(--confirm-btn-active-color);
}

.cancel-new-item {
    background-color: var(--cancel-btn-bg-color);
    color: var(--cancel-btn-color);
}

.cancel-new-item:hover {
    background-color: var(--cancel-btn-hover-color);
}

.cancel-new-item:active {
    background-color: var(--cancel-btn-active-color);
}