@import url('https://fonts.googleapis.com/css2?family=Keania+One&display=swap');

.content-title {
    font-family: 'Keania One', cursive;
    text-align: center;
    user-select: none;
}

.content-title span {
    padding: 2px 16px 4px 16px;
    border: 2px solid transparent;
    border-top: none;
}

.content:first-child .content-title span {
    border-color: var(--pros-color);
}

.content:last-child .content-title span {
    border-color: var(--cons-color);
}