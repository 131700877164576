.card-title {
    padding: 6px 0;
    color: var(--choice-title-color);
    background-color: var(--choice-title-bg-color);
    text-align: center;
    font-weight: 500;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-container {
	width: 100%;
	display: grid;
	grid-template-columns: 12% 76% 12%;
    align-items: center;
    cursor: pointer;
}

.title-controls {
    justify-self: end;
    display: none;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    margin-right: 6px;
}

.card-title:hover .edit-title-btn {
    visibility: visible;
}

.edit-title-btn {
    border-radius: 5px;
    padding: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    color: var(--choice-title-color);
    border: 1px solid var(--choice-title-color);
    visibility: hidden;
}

.edit-title-btn:hover {
    color: var(--choice-title-bg-color);
    background-color: var(--choice-title-color);
}

.result-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: end;
    padding: 0.15rem .35rem 0;
    margin-right: .35rem;
    border-radius: 4px;
    user-select: none;
}

.winner-icon {
    background-color: #22c55e;
    color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.not-winner-icon {
    background-color: #f43f5e;
    color: white;
}

@media only screen and (min-width: 600px) {
    .title-controls {
        display: flex;
    }
}