.App {
    height: 100%;
}

.header {
    padding: .35rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media only screen and (min-width: 420px) {
    .header {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}