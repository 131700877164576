.card-footer {
    padding-top: 4px;
}

.card-footer-controls {
    text-align: right;
    color: #7d7c83;
    font-size: .85rem;
    font-weight: 400;
}

.total-score {
    color: #fff;
    text-align: center;
    animation-duration: 3s;
    animation-name: fadeIn;
    display: inline-block;
    margin-right: 12px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.card-theme-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #7d7c83;
    margin-right: 4px;
    transition: color .25s ease;
}

.card-theme-btn:hover {
    color: white;
}

.score-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.score-explainer {
    display: inline-block;
    position: relative;
}

.score-explainer:hover .info-marker {
    opacity: 1;
}

.info-marker {
    color: white;
    background-color: transparent;
    padding: 2px 9px;
    border: 1px dashed white;
    border-radius: 50%;
    user-select: none;
    font-size: 0.875rem;
    line-height: 1.25;
    opacity: 0.7;
}

/* tooltip */
.score-explanation {
    position: absolute;
    bottom: 140%;
    left: -335%;
    color: white;
    background-color: #0ea5e9;
    list-style-position: inside;
    width: max-content;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 0.9rem;
}

/* the arrow of the tooltip */
.score-explanation::before {
    content: '';
    border: 12px solid transparent;
    position: absolute;
    top: 100%;
    left: 48%;
    border-top-color: #0ea5e9;
}