.content-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: .75rem;
    padding-bottom: .5rem;
}

.add-btn {
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    margin: 0 1rem .5rem 0;
}

.content:first-child .add-btn {
    color: var(--pros-color);
    border: 2px solid var(--pros-color);
}

.content:last-child .add-btn {
    color: var(--cons-color);
    border: 2px solid var(--cons-color);
}

.content:first-child .add-btn:hover {
    background-color: var(--pros-color);
    color: var(--pros-bg-color);
}

.content:last-child .add-btn:hover {
    background-color: var(--cons-color);
    color: var(--cons-bg-color);
}

/* .content:first-child .add-btn:active {
    background-color: var(--cancel-add-item-active-color);
    border-color: var(--cancel-add-item-active-color);
}

.content:last-child .add-btn:active {
    background-color: var(--title-background-color);
    border-color: var(--title-background-color);
} */

.total-weight-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0;
    animation-duration: 2s;
    animation-name: fadeIn;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.total-weight {
    color: var(--pros-bg-color);
    background-color: var(--pros-color);
    border-radius: 5px;
    padding: 3px 6px;
}

.content:last-child .total-weight {
    color: var(--cons-bg-color);
    background-color: var(--cons-color);
}